import React from 'react';
import './style.css';
import Portrait from '../../assets/photos/portrait5.jpg';
import Typing from 'react-typing-animation';
import emoji from 'emoji-dictionary';

const sillyFace = emoji.getUnicode("stuck_out_tongue_winking_eye");

const Logo = (props) => (
    <div className="logo">
        <img id="portrait" src={Portrait}></img>
        <div className="rightElement">
            <Typing speed={25}>
                <span style={{fontSize: '24px', fontFamily: 'monospace'}}>Hi, I'm Daniel and I am a master hacker</span>
                <Typing.Backspace delay={200} count={6} speed={50}/>
                <span style={{fontSize: '24px', fontFamily: 'monospace'}}>programmer! {sillyFace}</span>
            </Typing>
        </div>
    </div>
);
export default Logo;