import React from 'react';
import './style.css';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import DeepLearningIcon from '../../components/Icons/Companies/DeepLearningIcon';
import ECCouncilIcon from '../../components/Icons/Companies/ECCouncil';
import CourseraIcon from '../../components/Icons/Companies/Coursera';
import StanfordIcon from '../../components/Icons/Schools/StanfordIcon';

const Experience = (props) => {
    return (
        <div>
            <VerticalTimeline layout='1-column-left'>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="2020-2021"
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<DeepLearningIcon />}
                >
                <h3 className="vertical-timeline-element-title">Deep Learning Specialization (5 courses)</h3>
                <h4 className="vertical-timeline-element-subtitle">deeplearning.ai</h4>
                <p>
                    Completed the Deep Learning Specialization taught by Andrew Ng on Coursera,
                    which consists of 5 courses:
                    <ul>
                        <li>Neural Networks and Deep Learning</li>
                        <li>Improving Deep Neural Networks: Hyperparameter tuning, Regularization, and Optimization</li>
                        <li>Structured Machine Learning Projects</li>
                        <li>Convolutional Neural Networks</li>
                        <li>Sequence Models</li>                        
                    </ul>

                    <a id="dl" href="https://www.coursera.org/account/accomplishments/specialization/certificate/ZCWNMRUQ4UYC">See credential</a>
                    <br/>
                    <span style={{ color: 'darkslategray' }}>Credential ID: ZCWNMRUQ4UYC</span>
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="December 2020"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<CourseraIcon />}
                >
                <h3 className="vertical-timeline-element-title">Neural Networks and Deep Learning</h3>
                <h4 className="vertical-timeline-element-subtitle">Coursera</h4>
                <p>
                    <a href="https://www.coursera.org/account/accomplishments/certificate/K22BQRRJVU9M">See credential</a>
                    <br/>
                    Credential ID: K22BQRRJVU9M
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="January 2021"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<CourseraIcon />}
                >
                <h3 className="vertical-timeline-element-title">Convolutional Neural Networks</h3>
                <h4 className="vertical-timeline-element-subtitle">Coursera</h4>
                <p>
                    <a href="https://www.coursera.org/account/accomplishments/certificate/4D5TS2Z96E4R">See credential</a>
                    <br/>
                    Credential ID: 4D5TS2Z96E4R
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="January 2021"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<CourseraIcon />}
                >
                <h3 className="vertical-timeline-element-title">Sequence Models</h3>
                <h4 className="vertical-timeline-element-subtitle">Coursera</h4>
                <p>
                    <a href="https://www.coursera.org/account/accomplishments/certificate/D76B4R3WCVMG">See credential</a>
                    <br/>
                    Credential ID: D76B4R3WCVMG
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="January 2021"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<CourseraIcon />}
                >
                <h3 className="vertical-timeline-element-title">Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization</h3>
                <h4 className="vertical-timeline-element-subtitle">Coursera</h4>
                <p>
                    <a href="https://www.coursera.org/account/accomplishments/certificate/FVUD37ATHDKJ">See credential</a>
                    <br/>
                    Credential ID: FVUD37ATHDKJ
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="January 2021"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<CourseraIcon />}
                >
                <h3 className="vertical-timeline-element-title">Structured Machine Learning Projects</h3>
                <h4 className="vertical-timeline-element-subtitle">Coursera</h4>
                <p>
                    <a href="https://www.coursera.org/account/accomplishments/certificate/44ZGUZG7WKU3">See credential</a>
                    <br/>
                    Credential ID: 44ZGUZG7WKU3
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="August 2020"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<StanfordIcon />}
                >
                <h3 className="vertical-timeline-element-title">Machine Learning</h3>
                <h4 className="vertical-timeline-element-subtitle">Stanford Online</h4>
                <p>
                    <a href="https://www.coursera.org/account/accomplishments/certificate/GU2CCDQ3ZA6B">See credential</a>
                    <br/>
                    Credential ID: GU2CCDQ3ZA6B
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="September 2018"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<ECCouncilIcon />}
                >
                <h3 className="vertical-timeline-element-title">Certified Ethical Hacker (CEH)</h3>
                <h4 className="vertical-timeline-element-subtitle">EC-Council</h4>
                <p>
                    Credential ID: ECC38317806422
                </p>
                </VerticalTimelineElement>
            </VerticalTimeline> 
        </div>
    );
}

export default Experience;