import './index.css';
import Hero from '../../components/Hero';
import Experience from '../../containers/Experience';
import Projects from '../../containers/Projects';
import Certifications from '../../containers/Certifications';

const Home = () => {

  return (
    <div className="container">
      <Hero />
      <div className="leftColumn">
        <h2 className="columnHeader">Experience</h2>
        <Experience />
        <h2 className="columnHeader">Certifications</h2>
        <Certifications />
      </div>
      <div className="rightColumn">
        <h2 className="columnHeader">Projects</h2>
        <Projects />
      </div>
    </div>
  );
}

export default Home;
