import React from 'react';
import './style.css';

export default class Technologies extends React.Component {
    state = {
        
    }

    constructor(props) {
        super(props);
        this.names = props.names;
    }

    getIcons = () => {
        let divs = [];
        for (let i = 0; i < this.names.length; i++) {
            divs.push(
                <div>
                    <img 
                    src={`./img/${this.names[i]}.png`} 
                    style={{width: '30px'}} />
                </div>
            )
        }
        return divs;
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }

    getTags = () => {
        let divs = [];
        for (let i = 0; i < this.names.length; i++) {
            let nameUpper = this.capitalize(this.names[i])
            divs.push(
                <div className={`${this.names[i]}`}>
                    <span>{nameUpper}</span>
                </div>
            )
        }
        return divs;

    }

    render = () => (
        <div className="container">{this.getTags()}</div>
    )
}