import React, { Component } from 'react'
import Terminal from 'react-console-emulator'
 
const commands = {
  ls: {
    description: 'List files in the target directory.',
    usage: 'ls',
    fn: function () {
      return "skills.txt\ninterests.txt"
    }
  },
  cat: {
    description: 'Display the contents of a file.',
    usage: 'cat skills.txt',
    fn: function(arg) {
        if (arg == 'skills.txt') {
            return "- programming languages: python, java, C, haskell, HTML/CSS, javascript, typescript, perl\n- databases: mysql, ms-sql, sqlite, mongodb\n- frameworks: pytorch, keras, tensorflow, flask, react\n- technologies: git, linux, docker, redis, jira, AWS"
        } else if (arg == "interests.txt") {
            return "- machine (and deep) learning\n- full stack software engineering\n- offensive security\n- distributed systems"
        }
    }
  },
}
 
export default class MyTerminal extends Component {
  render () {
    return (
      <Terminal
        commands={commands}
        welcomeMessage={'DVM v1.0 (use the \'help\' command to start exploring...)'}
        promptLabel={'$'}
      />
    )
  }
}