import React from 'react';
import './style.css';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import GoogleIcon from '../../components/Icons/Companies/Google';
import LyftIcon from '../../components/Icons/Companies/LyftIcon';
import ClearwaterIcon from '../../components/Icons/Companies/ClearwaterIcon';
import BSUIcon from '../../components/Icons/Schools/BSUIcon';


const Experience = (props) => {
    return (
        <div>
            <VerticalTimeline layout='1-column-left'>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="Incoming June 2022"
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<GoogleIcon />}
                >
                <h3 className="vertical-timeline-element-title">Google</h3>
                <h4 className="vertical-timeline-element-subtitle lyftSubtitle">Software Engineer</h4>
                <p>
                    Google Cloud
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Summer 2021"
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<LyftIcon />}
                >
                <h3 className="vertical-timeline-element-title">Lyft</h3>
                <h4 className="vertical-timeline-element-subtitle lyftSubtitle">Software Engineering Intern</h4>
                <p>
                    Market Signals team.
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2018 - 2021"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<ClearwaterIcon />}
                >
                <h3 className="vertical-timeline-element-title">Clearwater Analytics</h3>
                <h4 className="vertical-timeline-element-subtitle">Data Intake Engineer</h4>
                <p>
                Developed and maintained a data ingestion engine for a fintech firm providing a SaaS solution for financial data aggregation, reconciliation and reporting.
                </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2018 - 2022"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<BSUIcon />}
                >
                <h3 className="vertical-timeline-element-title">Boise State University</h3>
                <h4 className="vertical-timeline-element-subtitle">B.S. Computer Science</h4>
                <p>
                    GPA: 3.91 (major)
                </p>
                <p>
                    Expected graduation: Spring 2022
                </p>
                <p>
                    Noteable coursework:
                    <ul>
                    <li>Natural Language Processing</li>
                    <li>Algorithm Design and Analysis</li>
                    <li>Data Structures</li>
                    <li>Programming Languages</li>
                    <li>Systems Programming</li>                        
                    <li>Microprocessors</li>  
                    <li>Digital Systems & Circuits</li> 
                    </ul>
                </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    );
}

export default Experience;