import React from 'react';
import './style.css';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import BYOBIcon from '../../components/Icons/Projects/BYOBIcon';
import PythonIcon from '../../components/Icons/Technologies/PythonIcon';
import HaskellIcon from '../../components/Icons/Technologies/HaskellIcon';
import JavaIcon from '../../components/Icons/Technologies/JavaIcon';
import BYOBPreview from '../../assets/photos/preview-dashboard.png';
import AutogradPreview from '../../assets/photos/autograd.png';
import MoonPreview from '../../assets/photos/moon-decision-boundary.png';
import SemanticVizPreview from '../../assets/photos/LabelledSemanticGraph.png';
import GithubAPI from '../../components/GithubAPI';
import Technologies from '../../components/Technologies';
import FakePredictionPreview from '../../assets/photos/fake-prediction.png';
import RealPredictionPreview from '../../assets/photos/real-prediction.png';
import AdminPreview from '../../assets/photos/admin-preview.png';
import MainPreview from '../../assets/photos/main-preview.png';



const Projects = (props) => {
    return (
        <div>
            <VerticalTimeline layout='1-column-right'>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(100,100,100)', color: '#fff' }}
                contentArrowStyle={{ borderLeft: '7px solid  rgb(100,100,100)' }}
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<BYOBIcon />}
                >
                <h3 className="vertical-timeline-element-title">BYOB</h3>
                <GithubAPI repo="byob" color="whitesmoke" defaultStars="7220" defaultForks="1765"/>
                <Technologies names={["python","javascript"]} />

                <div className="pill" style={{color: 'white', backgroundColor: 'rgb(220,23,23)'}}>Security</div>

                <img className="projectPreviews" src={BYOBPreview}/>
                <p>
                BYOB is an open-source post-exploitation framework for students, researchers and developers. It includes features such as:
                <ul>
                    <li>Pre-built C2 server</li>
                    <li>Custom payload generator</li>
                    <li>12 post-exploitation modules</li>
                </ul>
                It is designed to allow students and developers to easily implement their own code and add cool new features without having to write a C2 server or Remote Administration Tool from scratch.
                </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ color: '#000' }}
                contentArrowStyle={{ borderLeft: '7px solid  white' }}
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<PythonIcon width='40px'/>}
                >
                <h3 className="vertical-timeline-element-title">Is This Fake News?</h3>
                <GithubAPI repo="is-this-fake-news" color="grey" defaultStars="23" defaultForks="9"/>
                <Technologies names={["python"]} />

                <div className="pill" style={{color: 'white', backgroundColor: 'rgb(25,150,100)'}}>Machine Learning</div>

                <p>A Flask app which allows users to detect fake news using deep learning and natural language processing techniques, namely BERT fine-tuning and a multi-layer perceptron.</p>
                <br/>
                <img src={RealPredictionPreview} className='autogradPreviewLeft'/>
                <img src={FakePredictionPreview} className='autogradPreviewRight'/>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ color: '#000' }}
                contentArrowStyle={{ borderLeft: '7px solid  white' }}
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<PythonIcon width='40px'/>}
                >
                <h3 className="vertical-timeline-element-title">Autograd for Dummies</h3>
                <GithubAPI repo="autograd-for-dummies" color="grey"  defaultStars="22" defaultForks="1"/>
                <Technologies names={["python"]} />

                <div className="pill" style={{color: 'white', backgroundColor: 'rgb(25,150,100)'}}>Machine Learning</div>

                <p>A minimal autograd engine and neural network library written from scratch.</p>
                <br/>
                <img src={AutogradPreview} className='autogradPreviewLeft'/>
                <img src={MoonPreview} className='autogradPreviewRight'/>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ color: '#000' }}
                contentArrowStyle={{ borderLeft: '7px solid  white' }}
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<HaskellIcon width='40px'/>}
                >
                <h3 className="vertical-timeline-element-title">Semantic Viz</h3>

                <GithubAPI repo="semantic-viz" color="grey" defaultStars="7" defaultForks="1"/>
                <Technologies names={["haskell"]} />

                <div className="pill" style={{color: 'white', backgroundColor: 'rgb(25,100,220)'}}>Natural Language Processing</div>


                <p>A semantic distance visualizer written in Haskell using the Wordnet lexical database.</p>
                <br/>
                <img src={SemanticVizPreview} className="projectPreviews" />
                </VerticalTimelineElement>

                {/* <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ color: '#000' }}
                contentArrowStyle={{ borderLeft: '7px solid  white' }}
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<PythonIcon width='40px'/>}
                >
                <h3 className="vertical-timeline-element-title">Microservices</h3>
                <GithubAPI repo="microservices" color="grey" defaultStars="7" defaultForks="1"/>
                <Technologies names={["python"]} />

                <div className="pill" style={{color: 'white', backgroundColor: 'rgb(137,59,254)'}}>Microservices</div>

                <p>Boilerplate web app built with microservice architecture using React, Django, Flask, RabbitMQ.</p>
                <br/>
                <img src={AdminPreview} className='autogradPreviewLeft'/>
                <img src={MainPreview} className='autogradPreviewRight'/>
                </VerticalTimelineElement> */}


                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ color: '#000' }}
                contentArrowStyle={{ borderLeft: '7px solid  white' }}
                dateClassName="dateClass"
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff'}}
                icon={<PythonIcon width='40px'/>}
                >
                <h3 className="vertical-timeline-element-title">To Do List</h3>
                <GithubAPI repo="microblock" color="grey" defaultStars="5" defaultForks="2"/>
                <Technologies names={["python"]} />

                <div className="pill" style={{color: 'white', backgroundColor: 'rgb(137,59,254)'}}>Blockchain</div>

                <p>A minimal blockchain implementation with an interactive user interface which allows the user to visually explore the blockchain.</p>
                </VerticalTimelineElement>


            </VerticalTimeline>
        </div>
    );
}

export default Projects;