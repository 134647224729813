import React from 'react';
import './style.css';
import Card from '../UI/Card';
import Logo from '../Logo';
import MyTermial from '../MyTerminal';

const Hero = (props) => (
    <div>
        <Card>
            <div className="hero" style={{ padding: '50px 0'}}>
                <Logo/>
            </div>
            <MyTermial />
        </Card>
    </div>
);
export default Hero;