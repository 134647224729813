import React from 'react';
import './style.css';

import DeepLearningLogo from '../../../../assets/icons/deeplearning.png';

const DeepLearningIcon = (props) => (
    <div className="deepLearningIcon">
        <img src={DeepLearningLogo} width="40px" />
    </div>
);
export default DeepLearningIcon;