import React from 'react';
import './style.css';

import axios from 'axios';
import CountUp from 'react-countup';

export default class GithubAPI extends React.Component {


  constructor(props) {
    super(props);
    this.repo = props.repo;
    this.color = props.color;
    this.defaultStars = parseInt(props.defaultStars);
    this.defaultForks = parseInt(props.defaultForks);
    console.log("Default stars", this.defaultStars);
    console.log("Default forks", this.defaultForks);
    
    this.state = {
      stars: 0,
      forks: 0
    };
  }

  componentDidMount() {
    var self = this;
    axios.get(`https://api.github.com/repos/malwaredllc/${this.repo}`)
      .then(res => {
        const stars_count = res.data.stargazers_count;
        const forks_count = res.data.forks_count;
        this.setState({ stars: stars_count });
        this.setState({ forks: forks_count });
      })
      .catch(function (error) {
        console.log("Github API returned an error (probably ratelimit). Using default stars/forks...");
        this.setState({ stars: self.defaultStars})
        this.setState({ forks: self.defaultForks})
      })
  }

  render() {
    return (
        <div>
            <i class="fa fa-star githubStarIcon" style={{color: `${this.color}`}}> 
                <CountUp 
                    end={this.state.stars} 
                    duration={10}
                />
            </i>
            <i class="fa fa-code-fork githubForkIcon" style={{color: `${this.color}`}}>
                <CountUp 
                    end={this.state.forks} 
                    duration={3}
                 />
            </i>
        </div>
    )
  }
}